import { generateSrcSet } from '@asy/react-lib/url';
import { cn } from '@asy/shadcn-ui/lib/utils';

export interface ImageContentGridProps {
  imagePosition: 'left' | 'right';
  imageUrl: string;
  imageAlt: string;
  imageClassName?: string;
  className?: string;
  children: React.ReactNode;
}

export function ImageContentGrid({
  imagePosition,
  imageUrl,
  imageAlt,
  imageClassName,
  className,
  children,
}: ImageContentGridProps) {
  const srcSet = generateSrcSet(imageUrl, [320, 640, 960]);
  const imageSection = (
    <img src={imageUrl} srcSet={srcSet} alt={imageAlt} className={cn('w-full object-cover', imageClassName)} />
  );
  const contentSection = <div className="row-start-2 p-4 lg:row-start-auto lg:px-16 lg:py-12">{children}</div>;

  return (
    <div className={cn('grid w-full items-center lg:grid-cols-2', className)}>
      {imagePosition === 'left' ? imageSection : contentSection}
      {imagePosition === 'right' ? imageSection : contentSection}
    </div>
  );
}
