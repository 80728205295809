import { Button } from '@asy/shadcn-ui/components/button';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuLabel,
  DropdownMenuGroup,
} from '@asy/shadcn-ui/components/dropdown-menu';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { Link, UIMatch, useMatches } from '@remix-run/react';
import { MenuIcon } from 'lucide-react';
import { useMemo } from 'react';
import { RouteHandle } from '~/lib/route-handle';

interface HeaderProps {
  className?: string;
}

export function Header({ className }: HeaderProps) {
  const matches = useMatches() as unknown as UIMatch<unknown, RouteHandle | undefined>[];
  const hideHeaderShadow = useMemo(
    () => matches.some((match) => match.handle?.headerConfig.hideHeaderShadow),
    [matches]
  );

  return (
    <header
      className={cn(
        'fixed left-0 right-0 top-0 z-40 bg-background',
        { 'shadow-md': !hideHeaderShadow },
        { 'md:border-b': hideHeaderShadow },
        className
      )}
    >
      <nav className="container flex h-full items-center justify-between px-4">
        <div>
          <Link className="text-2xl font-bold text-gray-800 dark:text-white" to="/" prefetch="intent">
            <img
              className="h-16 w-auto"
              src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/logo.png"
              srcSet="
              https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/logo.png 640w,
              https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/logo.png 960w
              "
              alt="Abhyas School of Yoga"
            />
          </Link>
        </div>
        <NavLinks />
        <MobileNavLinks />
      </nav>
    </header>
  );
}

function NavLinks() {
  return (
    <nav className="hidden space-x-4 lg:block">
      <Link to="/about" prefetch="intent">
        <Button variant="link" className="font-serif text-lg font-light">
          About Us
        </Button>
      </Link>
      <Link to="/pain-management" prefetch="intent">
        <Button variant="link" className="font-serif text-lg font-light">
          Pain Management
        </Button>
      </Link>
      <Link to="/teachers-training" prefetch="intent">
        <Button variant="link" className="font-serif text-lg font-light">
          Teacher&apos;s Training
        </Button>
      </Link>
      <Link to="/service" prefetch="intent">
        <Button variant="link" className="font-serif text-lg font-light">
          Service
        </Button>
      </Link>
      <Link to="/activities" prefetch="intent">
        <Button variant="link" className="font-serif text-lg font-light">
          Activities
        </Button>
      </Link>
    </nav>
  );
}

function MobileNavLinks() {
  return (
    <nav className="lg:hidden">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button variant="ghost" className="px-0">
            <MenuIcon />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <Link to="/" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">Home</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <Link to="/about" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">About</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <DropdownMenuGroup>
            <DropdownMenuLabel className="font-serif text-base">Pain Management</DropdownMenuLabel>
            <Link to="/pain-management" prefetch="intent" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">Discover</DropdownMenuItem>
            </Link>
            <Link to="/pain-management/treatments" prefetch="intent" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">Treatments</DropdownMenuItem>
            </Link>
            <Link to="/pain-management/book-now" prefetch="intent" className="font-serif font-light">
              <DropdownMenuItem className="cursor-pointer text-base">Book Now</DropdownMenuItem>
            </Link>
          </DropdownMenuGroup>
          <DropdownMenuSeparator />
          <Link to="/teachers-training" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">Teacher&apos;s Training</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <Link to="/service" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">Service</DropdownMenuItem>
          </Link>
          <DropdownMenuSeparator />
          <Link to="/activities" prefetch="intent" className="font-serif font-light">
            <DropdownMenuItem className="cursor-pointer text-base">Activities</DropdownMenuItem>
          </Link>
        </DropdownMenuContent>
      </DropdownMenu>
    </nav>
  );
}
