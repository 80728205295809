import { Button } from '@asy/shadcn-ui/components/button';
import { FormControl, FormField, FormItem, FormMessage } from '@asy/shadcn-ui/components/form';
import { Input } from '@asy/shadcn-ui/components/input';
import { useFetcher } from '@remix-run/react';
import { useEffect, useRef } from 'react';
import { useRemixForm, RemixFormProvider as FormProvider } from 'remix-hook-form';
import { toast } from 'sonner';
import { SubscribeSchema, subscribeSchemaResolver } from '~/lib/validation-schemas';

export function SubscribeForm() {
  const fetcher = useFetcher();
  const form = useRemixForm<SubscribeSchema>({
    mode: 'onSubmit',
    resolver: subscribeSchemaResolver,
    defaultValues: {
      email: '',
    },
    fetcher,
    submitConfig: {
      method: 'POST',
      action: '/api/subscription',
    },
  });
  const loadingToastId = useRef<number | string | undefined>(undefined);
  const isLoading = ['loading', 'submitting'].includes(fetcher.state);
  const isSubmitted = form.formState.isSubmitSuccessful;
  const formErrors = form.formState.errors;
  const resetForm = form.reset;

  useEffect(() => {
    if (isSubmitted && isLoading && !loadingToastId.current) {
      loadingToastId.current = toast.loading('Subscribing...');
    } else if (!isLoading && loadingToastId.current) {
      toast.dismiss(loadingToastId.current);
      loadingToastId.current = undefined;
      if (isSubmitted && !formErrors) {
        toast.success('Thank you for subscribing!');
        resetForm();
      }
    }
  }, [isLoading, isSubmitted, loadingToastId, resetForm, formErrors]);

  return (
    <FormProvider {...form}>
      <fetcher.Form onSubmit={form.handleSubmit} className="flex w-full flex-col gap-6">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem className="w-full">
              <FormControl>
                <Input type="text" placeholder="Email" {...field} />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <Button type="submit" disabled={isLoading}>
          Subscribe
        </Button>
      </fetcher.Form>
    </FormProvider>
  );
}
