import { Button, ButtonProps } from '@asy/shadcn-ui/components/button';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { QuoteIcon } from 'lucide-react';
import * as React from 'react';
import 'swiper/css';
import 'swiper/css/a11y';
import 'swiper/css/autoplay';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Autoplay, Navigation, Pagination, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Typography } from '~/components/typography';
import { YouTubeDialog } from '~/components/youtube-dialog';
import './swiper.css';

export interface CarouselProps {
  testimonials: {
    imageUrl: string;
    imageAlt: string;
    name: string;
    designation: string;
    quote: string;
    videoUrl?: string;
  }[];
  className?: string;
  sliderClassName?: string;
}

export function Carousel({ testimonials, className, sliderClassName }: CarouselProps) {
  return (
    <Swiper
      className={cn('h-full w-full', className)}
      modules={[Autoplay, Navigation, Pagination, A11y]}
      speed={2000}
      autoplay={{
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }}
      slidesPerView={1}
      navigation
      pagination={{ clickable: true }}
      scrollbar={{ draggable: true }}
    >
      {testimonials.map((testimonial, index) => (
        <SwiperSlide key={`${testimonial.name}-${index}`} className={cn('w-full', sliderClassName)}>
          <div className="md:pl-24 md:pr-52">
            <div className="flex min-h-[550px] flex-col-reverse bg-accent text-left text-accent-foreground md:flex-col md:py-20 md:pl-20 md:pr-[30rem]">
              <div className="px-10 py-5 md:px-0 md:py-0">
                <QuoteIcon className="my-5 rotate-180 text-gray-600" strokeWidth={1} size={48} />
                <Typography className="text-pretty">{testimonial.quote}</Typography>
                <Typography variant="h2" className="mt-4">
                  {testimonial.name}
                </Typography>
                <Typography>{testimonial.designation}</Typography>
              </div>
              <div className="relative md:absolute md:right-24 md:top-1/2 md:w-[500px] md:-translate-y-1/2">
                <img className="w-full" src={testimonial.imageUrl} alt={testimonial.imageAlt} />
                {testimonial.videoUrl && (
                  <YouTubeDialog embedUrl={testimonial.videoUrl}>
                    <PlayIcon />
                  </YouTubeDialog>
                )}
              </div>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

const PlayIcon = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
  return (
    <Button
      ref={ref}
      variant="ghost"
      className="absolute left-1/2 top-1/2 h-fit -translate-x-1/2 -translate-y-1/2 rounded-full border-none bg-background p-0"
      {...props}
    >
      <img
        className="h-14 w-14"
        src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=128,quality=80,format=auto/images/play.png"
        alt="Play"
      />
    </Button>
  );
});
PlayIcon.displayName = 'PlayIcon';
