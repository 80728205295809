import { generateSrcSet } from '@asy/react-lib/url';
import { useMemo } from 'react';

export function AsyCenterImageCollage() {
  const image1SrcSet = useMemo(
    () =>
      generateSrcSet(
        'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-1.jpg',
        [320, 640, 960]
      ),
    []
  );
  const image2SrcSet = useMemo(
    () =>
      generateSrcSet(
        'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-2.jpg',
        [320, 640, 960]
      ),
    []
  );
  const image3SrcSet = useMemo(
    () =>
      generateSrcSet(
        'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-3.jpg',
        [320, 640, 960]
      ),
    []
  );
  const image4SrcSet = useMemo(
    () =>
      generateSrcSet(
        'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-4.jpg',
        [320, 640, 960]
      ),
    []
  );
  const image5SrcSet = useMemo(
    () =>
      generateSrcSet(
        'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-5.jpg',
        [320, 640, 960]
      ),
    []
  );
  const image6SrcSet = useMemo(
    () =>
      generateSrcSet(
        'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-6.jpg',
        [320, 640, 960]
      ),
    []
  );

  return (
    <div className="mt-12 grid gap-2 lg:grid-cols-2 lg:gap-6">
      <div>
        <div className="mb-2 aspect-[2.2/1] w-full overflow-hidden lg:mb-6">
          <img
            className="w-full"
            src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-1.jpg"
            srcSet={image1SrcSet}
            alt="asy-space"
          />
        </div>
        <div className="grid grid-cols-2 gap-2 lg:gap-6">
          <div className="aspect-[1.06/1] overflow-hidden">
            <img
              className="w-full"
              src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-3.jpg"
              srcSet={image3SrcSet}
              alt="asy-space"
            />
          </div>
          <div className="aspect-[1.06/1] overflow-hidden">
            <img
              className="w-full"
              src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-2.jpg"
              srcSet={image2SrcSet}
              alt="asy-space"
            />
          </div>
        </div>
      </div>
      <div>
        <div className="grid grid-cols-2 gap-2 lg:gap-6">
          <div className="aspect-[1.06/1] overflow-hidden">
            <img
              className="w-full"
              src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-5.jpg"
              srcSet={image5SrcSet}
              alt="asy-space"
            />
          </div>
          <div className="aspect-[1.06/1] overflow-hidden">
            <img
              className="w-full"
              src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-6.jpg"
              srcSet={image6SrcSet}
              alt="asy-space"
            />
          </div>
        </div>
        <div className="mt-2 aspect-[2.2/1] overflow-hidden lg:mt-6">
          <img
            className="w-full"
            src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/asy-space-4.jpg"
            srcSet={image4SrcSet}
            alt="asy-space"
          />
        </div>
      </div>
    </div>
  );
}
