import { Button } from '@asy/shadcn-ui/components/button';
import { Separator } from '@asy/shadcn-ui/components/separator';
import { cn } from '@asy/shadcn-ui/lib/utils';
import { Link } from '@remix-run/react';
import { MailIcon, PhoneCallIcon } from 'lucide-react';
import { SubscribeForm } from '~/components/forms/subscribe-form';
import { Typography } from '~/components/typography';

interface FooterProps {
  className?: string;
}

export function Footer({ className }: FooterProps) {
  const currentYear = new Date().getFullYear();
  return (
    <footer className={cn('bg-accent px-5 py-10 text-center md:px-10 md:text-left lg:px-36', className)}>
      <div className="flex flex-col items-center justify-between gap-10 md:flex-row md:items-start md:gap-0">
        <div className="flex flex-col space-y-4">
          <img
            src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/logo.png"
            alt="Abhyas School of Yoga"
            className="h-auto w-auto max-w-[200px]"
          />
          <Typography>
            Karari Garden, Nirmal Village <br />
            Vasai West, District Palghar <br />
            Mumbai, Maharashtra - 401304
          </Typography>
          <Typography>
            <a href="tel:+918485835675">
              <PhoneCallIcon className="inline" /> +91 848583 5675
            </a>
          </Typography>
          <Typography>
            <a href="mailto:info@abhyasschoolofyoga.org">
              <MailIcon className="inline" /> Email Us
            </a>
          </Typography>
        </div>
        <div className="space-y-4 text-center md:text-left">
          <Typography variant="h3" className="font-semibold">
            Quick Links
          </Typography>
          <ul className="space-y-4">
            <li>
              <Link to="/pain-management" prefetch="intent">
                Pain Management
              </Link>
            </li>
            <li>
              <Link to="/teachers-training" prefetch="intent">
                Teacher&apos;s Training Program
              </Link>
            </li>
            <li>
              <Link to="/service" preventScrollReset prefetch="intent">
                Service
              </Link>
            </li>
            <li>
              <Link to="/contact" prefetch="intent">
                Contact
              </Link>
            </li>
          </ul>
        </div>
        <div className="flex flex-col space-y-4">
          <Typography variant="h3" className="font-semibold">
            Subscribe To Our Newsletter
          </Typography>
          <div>
            <SubscribeForm />
          </div>
          <div className="flex justify-center gap-5 md:justify-start">
            <a
              className="rounded-full border border-primary bg-primary"
              href="https://www.instagram.com/abhyasschoolofyoga/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-12"
                src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/quality=80,format=auto/images/Instagram_white.png"
                alt="asy-instagram-link"
              />
            </a>
            <a
              className="rounded-full border border-primary bg-primary"
              href="https://www.facebook.com/abhyasschoolofyoga/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-12"
                src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/quality=80,format=auto/images/Facebook_white.png"
                alt="asy-facebook-link"
              />
            </a>
            <a
              className="rounded-full border border-primary bg-primary"
              href="https://www.youtube.com/@AbhyasSchoolofYoga"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-12"
                src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/quality=80,format=auto/images/Youtube_white.png"
                alt="asy-youtube-link"
              />
            </a>
            <a
              className="rounded-full border border-primary bg-primary"
              href="https://api.whatsapp.com/send?phone=918485835675"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="h-12"
                src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/quality=80,format=auto/images/WhatsApp_white.png"
                alt="asy-whatsapp-link"
              />
            </a>
          </div>
        </div>
      </div>
      <Separator className="mb-5 mt-10 bg-primary" />
      <div className="flex flex-col items-center justify-between gap-4 sm:flex-row sm:gap-0">
        <Typography variant="smallText">© {currentYear} Abhyas School of Yoga</Typography>
        <div className="flex items-center gap-4">
          <Button variant="link" className="p-0" asChild>
            <Link to="/feedback" prefetch="intent">
              <Typography variant="smallText">Feedback</Typography>
            </Link>
          </Button>
          <Button variant="link" className="p-0" asChild>
            <Link to="/terms-and-conditions" prefetch="intent">
              <Typography variant="smallText">Terms and Conditions</Typography>
            </Link>
          </Button>
        </div>
      </div>
    </footer>
  );
}
