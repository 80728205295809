import { Dialog, DialogContent, DialogTrigger } from '@asy/shadcn-ui/components/dialog';
import { type ReactNode } from 'react';

interface YouTubeDialogProps {
  embedUrl: string;
  children: ReactNode;
}

function YouTubeDialog({ embedUrl, children }: YouTubeDialogProps) {
  const enhancedEmbedUrl = new URL('?autoplay=1', embedUrl).href;

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="max-w-5xl border-0 p-0">
        <iframe
          className="aspect-video h-full w-full border-0"
          width="560"
          height="315"
          src={enhancedEmbedUrl}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </DialogContent>
    </Dialog>
  );
}

export { YouTubeDialog, type YouTubeDialogProps };
