import { zodResolver } from '@hookform/resolvers/zod';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { z } from 'zod';

export const subscribeSchema = z.object({
  email: z.string().email(),
});
export type SubscribeSchema = z.infer<typeof subscribeSchema>;
export const subscribeSchemaResolver = zodResolver(subscribeSchema);

export const ttcEnquirySchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string().refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
  dateOfBirth: z.coerce.date(),
  gender: z.enum(['MALE', 'FEMALE'], {
    errorMap: () => ({ message: 'Please select one of the given options' }),
  }),
  nationality: z.string().length(3),
  countryCode: z.string().length(3),
  healthIssues: z.object({
    heartProblems: z.boolean().default(false),
    bloodPressure: z.boolean().default(false),
    respiratoryProblems: z.boolean().default(false),
    jointProblems: z.boolean().default(false),
    spinalProblems: z.boolean().default(false),
    foodAllergy: z.boolean().default(false),
  }),
  termsAccepted: z.boolean().refine((value) => value, { message: 'Please accept the terms and conditions' }),
  turnstileResponse: z.string().min(1, { message: 'Please complete the captcha' }),
});
export type TtcEnquirySchema = z.infer<typeof ttcEnquirySchema>;
export const ttcEnquirySchemaResolver = zodResolver(ttcEnquirySchema);

export const feedbackSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string().refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
  message: z.string().min(1),
  turnstileResponse: z.string().min(1, { message: 'Please complete the captcha' }),
});
export type FeedbackSchema = z.infer<typeof feedbackSchema>;
export const feedbackSchemaResolver = zodResolver(feedbackSchema);

export const contactSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string().refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
  message: z.string().min(1),
  turnstileResponse: z.string().min(1, { message: 'Please complete the captcha' }),
});
export type ContactSchema = z.infer<typeof contactSchema>;
export const contactSchemaResolver = zodResolver(contactSchema);

export const doctorConsultationSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string().refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
  countryCode: z.string().length(3),
  state: z.string().min(1).optional(),
  city: z.string().min(1).optional(),
  consultationDate: z.coerce.date(),
  areaOfConcern: z.string().min(1),
  message: z.string().optional(),
  termsAccepted: z.boolean().refine((value) => value, { message: 'Please accept the terms and conditions' }),
  turnstileResponse: z.string().min(1, { message: 'Please complete the captcha' }),
});
export type DoctorConsultationSchema = z.infer<typeof doctorConsultationSchema>;
export const doctorConsultationSchemaResolver = zodResolver(doctorConsultationSchema);

export const painManagementBookNowSchema = z.object({
  firstName: z.string().min(1),
  lastName: z.string().min(1),
  email: z.string().email(),
  phoneNumber: z.string().refine(isValidPhoneNumber, { message: 'Invalid phone number' }),
  countryCode: z.string().length(3),
  state: z.string().min(1).optional(),
  city: z.string().min(1).optional(),
  arrivalDate: z.coerce.date(),
  areaOfConcern: z.string().min(1),
  numberOfDays: z.coerce.number().int().positive(),
  maleAttendees: z.coerce.number().int().positive().default(0),
  femaleAttendees: z.coerce.number().int().positive().default(0),
  message: z.string().optional(),
  termsAccepted: z.boolean().refine((value) => value, { message: 'Please accept the terms and conditions' }),
  turnstileResponse: z.string().min(1, { message: 'Please complete the captcha' }),
});
export type PainManagementBookNowSchema = z.infer<typeof painManagementBookNowSchema>;
export const painManagementBookNowSchemaResolver = zodResolver(painManagementBookNowSchema);
