import {
  DEFAULT_SCRIPT_ID as TURNSTILE_SCRIPT_ID,
  SCRIPT_URL as TURNSTILE_SCRIPT_URL,
} from '@marsidev/react-turnstile';
import type { LinksFunction, LoaderFunctionArgs } from '@remix-run/cloudflare';
import { Links, Meta, Outlet, Scripts, ScrollRestoration, isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { UnhandledError } from '~/components/page-section/unhandled-error-page';
import fontCss from '~/global-styles/fonts.css?url';
import tailwindStyles from '~/global-styles/tailwind.css?url';
import { Layout as RootLayout } from '~/layouts/root-layout';

export const links: LinksFunction = () => [
  { rel: 'stylesheet', href: tailwindStyles },
  { rel: 'stylesheet', href: fontCss },
];

export const loader = (args: LoaderFunctionArgs) => {
  return {
    turnstileSiteKey: args.context.cloudflare.env.TURNSTILE_SITE_KEY,
    invisibleTurnstileSiteKey: args.context.cloudflare.env.INVISIBLE_TURNSTILE_SITE_KEY,
  };
};

export function Layout({ children }: { children: React.ReactNode }) {
  const shouldRenderGA = import.meta.env.PROD;
  const gtagId = 'G-XK4PY48J6X';
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        {shouldRenderGA && <script async src={`https://www.googletagmanager.com/gtag/js?id=${gtagId}`} />}
        {shouldRenderGA && (
          <script
            id="gtag-init"
            dangerouslySetInnerHTML={{
              __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${gtagId}');
        `,
            }}
          />
        )}
        <script id={TURNSTILE_SCRIPT_ID} src={TURNSTILE_SCRIPT_URL} />
      </head>
      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  );
}

export const ErrorBoundary = () => {
  const error = useRouteError();
  console.log(error);

  if (isRouteErrorResponse(error)) {
    return (
      <UnhandledError title={error.statusText} statusCode={error.status} description={JSON.stringify(error.data)} />
    );
  } else if (error instanceof Error) {
    return <UnhandledError title="Something unexpected happened" statusCode={500} description={error.message} />;
  } else {
    return (
      <UnhandledError title="Something unexpected happened" statusCode={500} description={JSON.stringify(error)} />
    );
  }
};

export default function App() {
  return (
    <RootLayout>
      <Outlet />
    </RootLayout>
  );
}
