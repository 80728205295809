import { TestimonialCarouselProps } from '~/components/testimonial-carousel';

export const ASY_TESTIMONIALS: TestimonialCarouselProps['testimonials'] = [
  {
    name: 'Tishya Pradyut',
    designation: 'Content Creators',
    quote:
      'The accommodation at Abhyas School of Yoga was not only comfortable, but also designed with a deep sense of tranquility and serenity. The surroundings were breathtaking, with lush greenery and a peaceful atmosphere that instantly put me at ease. It was the ideal setting to disconnect from the outside world and focus on my well-being.',
    imageUrl:
      'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/testimonial/tishya-pradyut-hande.jpg',
    imageAlt: 'Tishya Pradyut',
    videoUrl: 'https://www.youtube.com/embed/cbfSgeEr0rA',
  },
  {
    name: 'Sasha and her Mom',
    designation: 'Fashion Model',
    quote:
      'The hospitality at this centre is truly exceptional. Every person here takes genuine care of my mom and me. Their willingness to help is sincere, and their actions are driven by true intention. The atmosphere is incredibly healing, and I am filled with gratitude for what this place has done for my mom. Thank you immensely.',
    imageUrl:
      'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/testimonial/sasha-n-her-mom.jpg',
    imageAlt: 'Sasha and her Mom',
    videoUrl: 'https://www.youtube.com/embed/5tAELJmuiqY',
  },
  {
    name: 'Gunjan Saini Bradhshaw',
    designation: 'Miss India Supra 2012 Winner',
    quote:
      "This place feels like a vacation, far better than just sleeping in and indulging in unhealthy food while sightseeing. I genuinely love everything about it—the place, the people, and the overall experience. Words can't fully capture the joy on my face, but I hope you can sense it and decide for yourself.",
    imageUrl:
      'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/testimonial/gunjan-saini-supra.jpg',
    imageAlt: 'Gunjan Saini Bradhshaw',
    videoUrl: 'https://www.youtube.com/embed/HRgHRCl9Sho',
  },
];

export const PAIN_MANAGEMENT_TESTIMONIALS: TestimonialCarouselProps['testimonials'] = [
  {
    name: 'Gunjan Saini Bradhshaw',
    designation: 'Miss India Supra 2012 Winner',
    quote:
      'I had Spondylolisthesis and had a lot of inflammation, back pain, leg and nerve pain. I found the food and the lifestyle helped ease the pain a great deal along with other kriyas. How pain is mind over matter- was pretty evident in the few days I was there. The Ayurvedic treatment and breathing kriyas were very instrumental in pain management and  connecting with self and letting go- in the physical and emotional body.',
    imageUrl:
      'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/testimonial/gunjan-saini-supra.jpg',
    imageAlt: 'Gunjan Saini Bradhshaw',
    videoUrl: 'https://www.youtube.com/embed/HRgHRCl9Sho',
  },
  {
    name: 'Dipali Kale, Nashik',
    designation: 'Home Maker',
    quote:
      'I came across Abhyas Ayurveda Health and Research Institute and decided to give it a try. The Ayurvedic doctor conducted a thorough assessment and listened attentively to my concerns. They explained the underlying causes of my back pain and recommended a personalized treatment plan tailored to address my specific needs. I underwent a series of Ayurvedic therapies, including Abhyanga (oil massage), Kati Basti (localized oil pooling).What truly impressed me was the holistic approach of Ayurveda. Not only did the therapies alleviate my pain, but I also received guidance on lifestyle modifications, dietary changes',
    imageUrl:
      'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/testimonial/deepak-kale-nashik.jpg',
    imageAlt: 'Dipali Kale, Nashik',
  },
];
