/**
 * Generates a source set string for an image URL, suitable for use in the srcSet attribute of an img tag.
 * This function modifies the URL to include different widths for responsive image display, keeping other
 * parameters like format and quality constant.
 *
 * @param {string} originalUrl - The original URL of the image, containing quality settings and the path.
 * @param {number[]} widths - An array of widths to be included in the source set.
 * @returns {string} A string formatted for use in the srcSet attribute, with URLs for each specified width.
 *
 * @example
 * const originalUrl = "https://public-assets.abhyas.mu/cdn-cgi/image/height=960px,format=auto,quality=80/images/homepage/organisation-transformation-journey.png";
 * const srcSet = generateSrcSet(originalUrl, [320, 640, 960]);
 * console.log(srcSet);
 * // Output:
 * // https://public-assets.abhyas.mu/cdn-cgi/image/format=auto,quality=80,width=320/images/homepage/organisation-transformation-journey.png 320w,
 * // https://public-assets.abhyas.mu/cdn-cgi/image/format=auto,quality=80,width=640/images/homepage/organisation-transformation-journey.png 640w,
 * // https://public-assets.abhyas.mu/cdn-cgi/image/format=auto,quality=80,width=960/images/homepage/organisation-transformation-journey.png 960w
 */
export function generateSrcSet(originalUrl: string, widths: number[]): string {
  const [baseUrl, settingsAndPath] = originalUrl.split('/cdn-cgi/image/');
  if (!settingsAndPath) {
    throw new Error('Invalid URL: missing settings and path');
  }
  const [settings, imagePath] = settingsAndPath.split('/images/');
  if (!settings || !imagePath) {
    throw new Error('Invalid URL: missing settings or image path');
  }
  // Remove the width setting if present, as we'll be adding our own widths from the array
  const settingsWithoutWidth = settings.replace(/width=\d+,?/, '');
  // Split the settings into an array for easier manipulation
  const settingsArray = settingsWithoutWidth.split(',');
  // Filter out the height setting if present, to focus on width-responsive images
  const filteredSettingsArray = settingsArray.filter((setting) => !setting.startsWith('height='));
  // Rejoin the settings, ensuring to add a comma only if there are settings present
  const baseSettings = filteredSettingsArray.length > 0 ? filteredSettingsArray.join(',') + ',' : '';
  // Generate the srcSet entries by iterating over the widths array
  const srcSetEntries = widths.map(
    (width) => `${baseUrl}/cdn-cgi/image/${baseSettings}width=${width}/images/${imagePath} ${width}w`
  );

  return srcSetEntries.join(',\n');
}
