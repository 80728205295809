import { MetaArgs, MetaDescriptor } from '@remix-run/cloudflare';

export interface SeoInput {
  title: string;
  description?: string;
  keywords?: string;
  previewImageSrc?: string;
  previewImageAlt?: string;
}

export function getSeoMeta(
  { title, description, keywords, previewImageSrc, previewImageAlt }: SeoInput,
  args: MetaArgs,
  extraMeta?: MetaDescriptor[]
): MetaDescriptor[] {
  if (!previewImageSrc) {
    previewImageSrc =
      'https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=512,format=auto,quality=80/images/asy-1.jpg';
  }
  if (!previewImageAlt) {
    previewImageAlt = 'Abhyas School of Yoga';
  }
  if (!description) {
    description =
      'Abhyas school of yoga is one such school of yog which talks about and imparts, not only talks about but also imparts, the real essence of yog';
  }
  if (!keywords) {
    keywords =
      'abhyas, school, yoga, meditation, asan, pranayam, Vihangam Yoga, Confidence, transformation, prana, Naamdeo, yog';
  }

  const baseMeta: MetaDescriptor[] = [
    { title: `${title} | Abhyas School of Yoga` },
    { name: 'description', content: description },
    { name: 'keywords', content: keywords },
    {
      tagName: 'link',
      rel: 'canonical',
      href: new URL(args.location.pathname, 'https://www.abhyasschoolofyoga.org').toString(),
    },
    { name: 'twitter:site', content: '@vybangalore' },
    { name: 'twitter:url', content: 'https://www.abhyasschoolofyoga.org' },
    { name: 'twitter:title', content: title },
    { name: 'twitter:description', content: description },
    { property: 'og:site_name', content: 'Abhyas School of Yoga' },
    { property: 'og:type', content: 'website' },
    { property: 'og:url', content: 'https://www.abhyasschoolofyoga.org' },
    { property: 'og:title', content: title },
    { property: 'og:description', content: description },
  ];

  const previewImageMeta: MetaDescriptor[] = [
    { name: 'twitter:card', content: 'summary_large_image' },
    { name: 'twitter:image', content: previewImageSrc },
    { name: 'twitter:image:alt', content: previewImageAlt },
    { property: 'og:image', content: previewImageSrc },
    { property: 'og:image:alt', content: previewImageAlt },
    { property: 'og:image:url', content: previewImageSrc },
    { property: 'og:image:secure_url', content: previewImageSrc },
  ];

  return [...baseMeta, ...previewImageMeta, ...(extraMeta ?? [])];
}
