import { Button } from '@asy/shadcn-ui/components/button';
import type { MetaFunction } from '@remix-run/cloudflare';
import { Link } from '@remix-run/react';
import { ContentSection } from '~/components/page-layout/content-section';
import { HeroSection } from '~/components/page-layout/hero-section';
import { ImageContentGrid } from '~/components/page-section/image-content-grid';
import { TestimonialCarousel } from '~/components/testimonial-carousel';
import { Typography } from '~/components/typography';
import { getSeoMeta } from '~/lib/seo';
import { ASY_TESTIMONIALS } from '~/ui-data/testimonials';
import { AsyCenterImageCollage } from './asy-center-image-collage';
import { TrainerCard } from './trainer-card';

export const meta: MetaFunction = (args) => {
  return getSeoMeta(
    {
      title: 'Home',
    },
    args
  );
};

export default function IndexPage() {
  return (
    <>
      <HeroSection className="lg:h-[calc(100vh-70px)]">
        <video
          loop
          muted
          playsInline
          autoPlay
          className="h-full w-full object-cover"
          poster="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=1280,quality=80,format=auto/images/home-video-cover.png"
        >
          <source src="https://public-assets.abhyasschoolofyoga.org/videos/home.webm" type="video/webm" />
          <source src="https://public-assets.abhyasschoolofyoga.org/videos/home.mp4" type="video/mp4" />
        </video>
      </HeroSection>
      <ContentSection>
        <div className="grid items-center gap-8 lg:grid-cols-2 lg:gap-24">
          <div className="row-start-2 lg:row-start-auto">
            <Typography variant="tagline">Abhyas School of Yoga</Typography>
            <Typography variant="h1">Real Essence of Yog</Typography>
            <div className="mt-6">
              <Typography>
                Abhyas School Of Yoga is founded on the ancient Rishi tradition and tenets of &apos;Guru-Shishya&apos;
                Paraṃparā. ASY not only teaches, but also imparts the most ancient principles and practices of
                &apos;Yog&apos;.
              </Typography>
              <Typography className="mt-4">
                The highest peak of one&apos;s performance can only be achieved by synchronization of body and mind.
                This can bring about extraordinary results. Abhyas School of Yoga imparts that level of efficiency.
              </Typography>
              <Link to="/about" prefetch="intent">
                <Button className="mt-8">Discover More</Button>
              </Link>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-6 lg:gap-12">
            <div className="relative mt-16 h-[250px] overflow-hidden shadow-xl md:mt-24 md:h-[400px]">
              <img
                className="h-full w-full object-cover"
                src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/asy-1.jpg"
                alt="Abhyas School of Yoga Center"
              />
            </div>
            <div className="relative h-[250px] overflow-hidden shadow-xl md:h-[400px]">
              <img
                className="h-full w-full object-cover"
                src="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=640,quality=80,format=auto/images/asy-2.jpg"
                alt="Abhyas School of Yoga Meditation"
              />
            </div>
          </div>
        </div>
      </ContentSection>
      <ContentSection className="bg-accent px-0 py-0 text-accent-foreground lg:px-0 lg:py-0 xl:px-0 xl:py-0">
        <ImageContentGrid
          imagePosition="left"
          imageUrl="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/pain-management/pain-management.jpg"
          imageAlt="Service in the areas of Education"
          imageClassName="aspect-[1.25/1]"
        >
          <Typography variant="h1" className="mb-8">
            Pain Management
          </Typography>
          <Typography className="mb-6">
            We specialize in alleviating chronic pain, particularly in the back and neck. Our approach combines the
            ancient wisdom of Ayurveda with the therapeutic practices of Yoga, ensuring comprehensive and effective
            solutions for persistent discomfort.
          </Typography>
          <Typography className="mb-6">
            Understanding the debilitating nature of chronic pain, we have crafted a tailored environment that
            harmonizes Ayurvedic principles and Yoga therapy. Our aim is to provide not just relief but a holistic path
            to reclaiming well-being. If you&apos;re seeking to break free from the confines of chronic pain, our center
            offers compassionate care and personalized attention. Through our unique integration of Ayurveda and Yoga,
            we empower individuals to embrace vitality and vitality anew, embarking on a journey towards healing and
            transformation.
          </Typography>
          <Link to="/pain-management" prefetch="intent">
            <Button>Know More</Button>
          </Link>
        </ImageContentGrid>
      </ContentSection>
      <ContentSection>
        <div className="text-center">
          <Typography variant="h1" className="mb-8">
            Our Center
          </Typography>
          <Typography>
            Located in the midst of Mother Nature&apos;s serenity, far from the frantic noise of city-life, our centre
            provides the optimal place for learning and living the Yogic way of life. In the large, breathable space,
            the Centre has in-house solar power; hygienically-kept, private rooms; a well-equipped Yoga hall; a big
            Meditation hall; library; dining hall; and a beautiful herbal garden.
          </Typography>
        </div>
        <AsyCenterImageCollage />
      </ContentSection>
      <ContentSection className="bg-accent px-0 py-0 text-accent-foreground lg:px-0 lg:py-0 xl:px-0 xl:py-0">
        <ImageContentGrid
          imagePosition="left"
          imageUrl="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/ttc.jpg"
          imageAlt="Service in the areas of Education"
          imageClassName="aspect-[1.25/1]"
        >
          <Typography variant="h1" className="mb-8">
            Teacher’s Training Program (TTP)
          </Typography>
          <Typography className="mb-6">
            The teachings in the course are based on the eternal knowledge of Yog as written by Maharshi Sadguru Sadafal
            Deo Ji Maharaj. Participants will also go through and imbibe the knowledge as described in ancient yoga
            texts like the Yog Sutra of Maharishi Patanjali, Srimad Bhagavad Gita, the Hatha Yoga Pradipika, to name a
            few.
          </Typography>
          <Typography className="mb-6">
            Practical applications of Yoga in terms of lifestyle, practice of the Shat Kriyas, ability to demonstrate
            and teach yoga postures and breathing techniques make up the practical aspect of the course.
          </Typography>
          <Link to="/teachers-training" prefetch="intent">
            <Button>Learn More</Button>
          </Link>
        </ImageContentGrid>
      </ContentSection>
      <ContentSection>
        <Typography variant="h1" className="mb-8 text-center">
          Our Trainers
        </Typography>
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-4">
          <TrainerCard
            name="Vibhuti"
            description="Yoga & Sanskrit"
            imageUrl="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/trainers/vibhuti.jpg"
          >
            <Typography className="text-base font-light">
              Namaskar! I am Vibhuti, a student of the yogic discipline and the Indian cultural heritage. Since the age
              of 11, I have been living in gurukulas and āśramas and studying yoga, mantras, Ayurveda and the lifestyle
              of yogis.
            </Typography>
            <Typography className="mt-4 text-base font-light">
              I had the privilege of working under the guidance of Naam Deo at the 2019 ardhakuṃbha at Prayag Raj and
              for the time I felt what it was like to operate so closely under the divine mentorship of the Yogee of a
              higher order. The way I was healed and all my constructs were resolved- made me never want to leave. And
              that’s exactly what I did.
            </Typography>
            <Typography className="mt-4 text-base font-light">
              I pledged my life to the cause of Naam Deo, of taking Yog, Ayurveda and the Indian heritage to the world
              and was bestowed with the honour of being an instructor at the Abhyas School of Yoga.
            </Typography>
            <Typography className="mt-4 text-base font-light">
              Through this school of Yog, The Master imparts &apos;sthe real essence of Yog&apos;s and tells us- How we
              could find ourselves and &apos;sdo what is needed&apos;s. I am super excited to have you on board on this
              transformative yogic journey, which will make you tap into the unimagined possibilities of your life.
            </Typography>
          </TrainerCard>
          <TrainerCard
            name="Ishmeet"
            description="Yoga & Lifestyle"
            imageUrl="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/trainers/ishmit.jpg"
          >
            <Typography className="text-base font-light">
              I still remember the day I first saw some students practising yogasan on the school stage on 4th April
              2007. It was at that moment that I instantly felt that this is what I wanted to pursue. Initially,I was
              extremely fascinated by the asanas, and I even started practising the poses regularly with the hope that
              one day I too will be in my school yoga team. From the very first day till the present moment, there
              hasn&apos;t been a single day that I have not thought of yoga. After years of practice and winning
              multiple competitions at the state level, I finally decided to make my passion, my profession.
            </Typography>
            <Typography className="mt-4 text-base font-light">
              The next phase of my yoga journey commences in June 2018, when I learned that yoga is not just about
              Asanas, it goes way deeper than what I had imagined. I first did a month-long Yoga Instructors’ Course in
              June 2018, and with that started my graduation Degree in B.Sc. Yoga Therapy in July 2018. Along with that,
              I also began teaching in a yoga studio which gave me a whole different level of learning experience. Every
              day was new learning. As I was going a whole lot deeper into the theoretical part of yoga, it made me
              realise that there&apos;s still something missing in my yoga journey, I wanted to know the real path of
              yoga that has been mentioned in all the ancient yogic texts.
            </Typography>
            <Typography className="mt-4 text-base font-light">
              And the result of my dedicated search was my discovery of the &apos;Abhyas School of Yoga&apos;, where the
              Real Essence of Yog lies. Under the Master&apos;s guidance, I have learnt so many things about myself and
              yog. There were still many challenges every day in following the yogic discipline like changing the food
              habits or the past lifestyle. There are still struggles but those struggles don&apos;t stop me from going
              forward. Rather it has become a tool to keep me inspired to change for the better. And you know there is a
              saying if something doesn&apos;t challenge you it won&apos;t change you either. Challenges are blessings
              in disguise, to make a shift in us.
            </Typography>
          </TrainerCard>
          <TrainerCard
            name="Nivedita"
            description="Yoga & Sanskrit"
            imageUrl="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/trainers/nivedita.jpg"
          >
            <Typography className="text-base font-light">
              I started my yoga journey a while back, and the early stages of my learning and teaching in this field
              were all about glamourous physical postures. This phase lasted for quite some time and during that, I had
              the privilege of experiencing this discipline- as taught by different yoga schools across India. I
              completed my BSc. in Yoga Therapy from one of the most renowned Yoga institutes and Level 3 exam conducted
              by the Yoga Certification Board, Ministry of Ayush, Govt. of India. I went down to Mysore to learn
              Ashtanga and Vinyasa. Conducted Hatha yoga, Pranayama and Mindfulness-based classes. I have also been
              practising Iyengar Yoga since 2017. I started teaching a mix-up of well-guided techniques that I had
              picked up after learning under experienced yoga teachers from different parts of India.
            </Typography>
            <Typography className="mt-4 text-base font-light">
              But the most important thing was realised after coming under the Master&apos;s shelter- that yoga is not
              limited to the alluring postures but it actually starts when you integrate your prana with your movements.
              When you start becoming aware of every moment and becoming more stable at all times. You learn to get in
              touch with the innermost essence of your being. This essence is beyond the ego. It is fearless, it is
              free. What the world needs today- is to be more conscious of every movement that we make. The Master is
              imparting this very essence and more through the trainers at the Abhyas School of Yoga. So come aboard and
              let&apos;s go through this awareness journey together and become the best version of ourselves.
            </Typography>
            <Typography className="mt-4 text-base font-light">
              Developing an in-depth understanding of the body&apos;s geometry with different alignment principles. With
              great emphasis on awareness, participants explore breath awareness and calmness in the thought.
            </Typography>
          </TrainerCard>
          <TrainerCard
            name="Richard"
            description="Yoga & Sanskrit"
            imageUrl="https://public-assets.abhyasschoolofyoga.org/cdn-cgi/image/width=960,quality=80,format=auto/images/trainers/richard.jpg"
          >
            <Typography className="text-base font-light">
              Namaskar! I am Richard - a devoted practitioner of the Yogic Discipline. My life has been turned around
              completely by the &apos;sGrace of The Master&apos;s after having lived through diverse phases of Psychotic
              Clinical Depression accompanied by Bipolar Disorder & Anxiety Disorder which persisted for a period of 8
              long years.
            </Typography>
            <Typography className="mt-4 text-base font-light">
              I am passionate soul whose ultimate purpose of life lies in serving humanity by &apos;ascending human
              consciousness to a higher dimension of life&apos;s, eradicating misery from every soul in grief & despair,
              and eliminating all forms of racism on the planet. A firm believer of the mission of global peace and
              harmony of Naam Deo!
            </Typography>
          </TrainerCard>
        </div>
      </ContentSection>
      <ContentSection className="pt-0 lg:pt-0 xl:pt-0">
        <div className="text-center">
          <Typography variant="tagline">Testimonials</Typography>
          <Typography variant="h1" className="mb-8">
            Stories of transformation
          </Typography>
        </div>
        <TestimonialCarousel testimonials={ASY_TESTIMONIALS} />
      </ContentSection>
    </>
  );
}
