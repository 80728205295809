import { generateSrcSet } from '@asy/react-lib/url';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@asy/shadcn-ui/components/dialog';
import { ScrollArea } from '@asy/shadcn-ui/components/scroll-area';
import { ReactNode } from 'react';
import { Typography } from '~/components/typography';

export interface TrainerCardProps {
  name: string;
  description: string;
  imageUrl: string;
  children: ReactNode;
}

export function TrainerCard({ name, description, imageUrl, children }: TrainerCardProps) {
  const srcSet = generateSrcSet(imageUrl, [320, 640, 960]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div>
          <div className="cursor-pointer">
            <div className="relative aspect-[1/1.25] w-full">
              <img className="w-full object-cover shadow-md" src={imageUrl} srcSet={srcSet} alt={name} />
            </div>
            <div className="relative -top-12 z-10 mx-4 bg-background px-4 py-2 text-center shadow-lg lg:-top-8">
              <Typography variant="h4" className="font-semibold text-primary">
                {name}
              </Typography>
              <Typography>{description}</Typography>
            </div>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent className="h-[90dvh] max-w-[70dvw] overflow-hidden border-0 p-0 sm:rounded-none md:h-[65dvh]">
        <div className="grid h-full max-h-full w-full md:grid-cols-5">
          <div className="h-[30dvh] md:col-span-2 md:h-auto">
            <img
              className="h-full w-full object-cover object-top md:h-full"
              src={imageUrl}
              srcSet={srcSet}
              alt={name}
            />
          </div>
          <div className="h-[60dvh] md:col-span-3 md:h-full">
            <DialogHeader className="h-[12dvh] justify-center bg-accent px-4 py-2 text-accent-foreground md:h-[10dvh]">
              <DialogTitle asChild>
                <Typography variant="h2" className="text-3xl text-primary">
                  {name}
                </Typography>
              </DialogTitle>
              <DialogDescription asChild>
                <Typography className="font-serif font-light uppercase tracking-widest text-gray-500">
                  {description}
                </Typography>
              </DialogDescription>
            </DialogHeader>
            <ScrollArea className="h-[55dvh] p-6">{children}</ScrollArea>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}
