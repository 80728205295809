import { cn } from '@asy/shadcn-ui/lib/utils';

export function HeroSection({ children, className }: { children: React.ReactNode; className?: string }) {
  return (
    <section
      className={cn(
        // We subtract the height of the navbar twice in lg: screens
        'relative h-[calc(50vh-60px)] sm:h-[calc(50vh-70px)] md:h-[calc(50vh-70px)] lg:h-[calc(100vh-140px)]',
        className
      )}
    >
      {children}
    </section>
  );
}
